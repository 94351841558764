import React from "react";
import HeaderField from "../../container/header/Index";
import QRContainer from "./components/QRContainer";
import ErrorBoundary from "../../component/error-boundry/ErrorBoundry";
// import { Player } from "@lottiefiles/react-lottie-player";
// import ANIMATION from "../../animation";
const Dashboard = () => {
  return (
    <ErrorBoundary>
      <HeaderField />
      <QRContainer />
      {/* <Player
        autoplay
        src={ANIMATION.done}
        style={{ height: "300px", width: "300px" }}
      ></Player> */}
    </ErrorBoundary>
  );
};

export default Dashboard;
