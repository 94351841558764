import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/login/Index";
import ProtectedRoute from "../protected-route/ProtectedRoute";
import Dashboard from "../pages/dashboard/Index";
import ErrorPage from "../pages/error-page/Index";

export const router = createBrowserRouter([
  {
    path: "*",
    element: <ErrorPage />,
  },
  {
    path: "/something-went-wrong",
    element: (
      <ErrorPage
        errorCode={501}
        errorTitle="Something Went Wrong"
        errorMessage="We encounter some internal error, Please try again later."
      />
    ),
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
    ],
  },
]);
